import React, { SVGAttributes } from 'react'

export const Branch: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="8" height="8" viewBox="0 0 8 8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4 7V5.28571M4 5.28571L1 2.71429M4 5.28571L7 2.71429M4 5.28571V1"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
