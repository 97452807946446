import React, { SVGAttributes } from 'react'

export const File: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 4.00004L9 4.00004C8.02893 4.00004 7.40122 4.00216 6.93871 4.06434C6.50497 4.12266 6.36902 4.21681 6.2929 4.29293C6.21677 4.36906 6.12263 4.505 6.06431 4.93874C6.00213 5.40125 6 6.02896 6 7.00004L6 17C6 17.9711 6.00213 18.5988 6.06431 19.0613C6.12263 19.4951 6.21677 19.631 6.2929 19.7071C6.36902 19.7833 6.50497 19.8774 6.93871 19.9357C7.40121 19.9979 8.02893 20 9 20H15C15.9711 20 16.5988 19.9979 17.0613 19.9357C17.495 19.8774 17.631 19.7833 17.7071 19.7071C17.7832 19.631 17.8774 19.4951 17.9357 19.0613C17.9979 18.5988 18 17.9711 18 17V10H15L14.9456 10H14.9456C14.5215 10.0001 14.1094 10.0002 13.7695 9.95447C13.3863 9.90295 12.949 9.77743 12.5858 9.41421C12.2226 9.051 12.0971 8.61372 12.0455 8.23052C11.9998 7.89061 11.9999 7.47849 12 7.05442V7.05441L12 7V4.00004ZM17.5858 8L14 4.41426V7C14 7.49967 14.0021 7.77383 14.0277 7.96402L14.0287 7.97131L14.036 7.97231C14.2262 7.99788 14.5003 8 15 8H17.5858ZM14.3512 2.15228C13.9753 1.99661 13.5728 1.99839 13.2564 1.99978L13.1716 2.00004L9 2.00004L8.93417 2.00004C8.04769 1.99999 7.28387 1.99994 6.67221 2.08218C6.0167 2.17031 5.38835 2.36906 4.87868 2.87872C4.36902 3.38838 4.17028 4.01673 4.08215 4.67225C3.99991 5.28391 3.99995 6.04772 4 6.93421L4 7.00004L4 17.0659C3.99995 17.9524 3.99991 18.7162 4.08215 19.3278C4.17028 19.9833 4.36902 20.6117 4.87868 21.1214C5.38835 21.631 6.0167 21.8298 6.67221 21.9179C7.28388 22.0001 8.0477 22.0001 8.9342 22L15.0658 22C15.9523 22.0001 16.7161 22.0001 17.3278 21.9179C17.9833 21.8298 18.6117 21.631 19.1213 21.1214C19.631 20.6117 19.8297 19.9833 19.9179 19.3278C20.0001 18.7162 20.0001 17.9524 20 17.0659L20 8.82846L20.0003 8.74368C20.0017 8.42726 20.0034 8.0247 19.8478 7.64888C19.6921 7.27307 19.4062 6.98967 19.1815 6.76692L19.1213 6.70715L15.2929 2.87872L15.2331 2.81858C15.0104 2.59385 14.727 2.30795 14.3512 2.15228Z"
    />
  </svg>
)
