import React, { SVGAttributes } from 'react'

export const RadioChecked: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 21 21" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs>
      <mask id="hole">
        <rect width="21" height="21" rx="11" fill="white" />
        <rect width="13" height="13" rx="11" x="4" y="4" fill="black" />
      </mask>
    </defs>
    <rect width="21" height="21" rx="11" mask="url(#hole)" />
  </svg>
)
