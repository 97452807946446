import React, { SVGAttributes } from 'react'

export const Logo: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 270 568"
    fill="currentColor"
    color="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M186.873 207.004C186.873 212.876 188.777 217.616 192.558 221.131C196.219 224.646 200.973 226.444 206.991 226.51H281.074V154.224H206.991C206.618 154.224 206.206 154.224 205.873 154.264C200.454 154.451 195.966 156.315 192.491 159.683C188.79 163.079 186.886 167.859 186.886 173.917V207.004H186.873ZM156.328 154.264C158.312 148.512 161.521 143.28 166.048 138.486C173.904 129.898 184.782 125.598 198.563 125.598H313.922V236.47C313.922 247.241 305.068 255.976 294.11 255.976H198.563C184.995 255.976 174.117 251.648 166.048 242.821C158.019 234.14 154.012 224.14 154.012 212.876V168.658C154.012 163.531 154.771 158.751 156.328 154.264Z"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M128.874 255.962H96.0928V125.584H128.874V255.962Z"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.5791 125.584H70.9542V236.456C70.9542 247.228 62.0999 255.962 51.1419 255.962H43.5791V125.584Z"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M220.053 409.322H229.133C235.192 409.322 240.904 406.593 244.685 401.879L280.089 357.595V433.316H311.978V318.529C311.978 309.928 304.881 302.938 296.16 302.938H285.987C281.088 302.938 276.494 305.148 273.498 308.929L223.794 371.615L173.957 308.929C170.975 305.148 166.368 302.938 161.508 302.938H152.267C143.52 302.938 136.423 309.928 136.423 318.529V433.316H169.204V357.595L204.488 401.853C208.229 406.566 213.981 409.336 220.04 409.336L220.053 409.322Z"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M112.563 433.316H79.7822V302.951H112.563V433.316Z"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.5791 302.965H55.9219V433.303H43.5791V302.965Z"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M346.57 116.291L43.5792 50.5295V541.325L346.57 437.23V116.291ZM6.24462 1.39805L356.835 79.569V444.54C237.895 485.403 118.941 526.266 0 567.129V0L6.24462 1.38473V1.39805Z"
      fill="#FEFEFE"
    />
  </svg>
)
