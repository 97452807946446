import React, { SVGAttributes } from 'react'

export const ArrowWithStickTop: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="#000000" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6365 6.36296L8.00012 1.72656L3.36372 6.36296L4.63652 7.63575L7.10005 5.17221L7.10005 12.9994H8.90005V5.17208L11.3637 7.63575L12.6365 6.36296Z"
    />
  </svg>
)
