import React, { SVGAttributes } from 'react'

export const Calculator: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="#188967" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.2 2C5.43269 2 4 3.43269 4 5.2V18.8C4 20.5673 5.43269 22 7.2 22H16.8C18.5673 22 20 20.5673 20 18.8V5.2C20 3.43269 18.5673 2 16.8 2H7.2ZM6 5.2C6 4.53726 6.53726 4 7.2 4H16.8C17.4627 4 18 4.53726 18 5.2V18.8C18 19.4627 17.4627 20 16.8 20H7.2C6.53726 20 6 19.4627 6 18.8V5.2ZM9 5.90039C8.44772 5.90039 8 6.34811 8 6.90039C8 7.45268 8.44772 7.90039 9 7.90039H15C15.5523 7.90039 16 7.45268 16 6.90039C16 6.34811 15.5523 5.90039 15 5.90039H9ZM8.1001 17C8.1001 16.4477 8.54781 16 9.1001 16H12.0001C12.5524 16 13.0001 16.4477 13.0001 17C13.0001 17.5523 12.5524 18 12.0001 18H9.1001C8.54781 18 8.1001 17.5523 8.1001 17ZM15 16C14.4477 16 14 16.4477 14 17C14 17.5523 14.4477 18 15 18H15.1C15.6523 18 16.1 17.5523 16.1 17C16.1 16.4477 15.6523 16 15.1 16H15ZM14 14C14 13.4477 14.4477 13 15 13H15.1C15.6523 13 16.1 13.4477 16.1 14C16.1 14.5523 15.6523 15 15.1 15H15C14.4477 15 14 14.5523 14 14ZM15 10C14.4477 10 14 10.4477 14 11C14 11.5523 14.4477 12 15 12H15.1C15.6523 12 16.1 11.5523 16.1 11C16.1 10.4477 15.6523 10 15.1 10H15ZM8 14C8 13.4477 8.44772 13 9 13H9.09998C9.65226 13 10.1 13.4477 10.1 14C10.1 14.5523 9.65226 15 9.09998 15H9C8.44772 15 8 14.5523 8 14ZM9 10C8.44772 10 8 10.4477 8 11C8 11.5523 8.44772 12 9 12H9.09998C9.65226 12 10.1 11.5523 10.1 11C10.1 10.4477 9.65226 10 9.09998 10H9ZM11 14C11 13.4477 11.4477 13 12 13H12.1C12.6523 13 13.1 13.4477 13.1 14C13.1 14.5523 12.6523 15 12.1 15H12C11.4477 15 11 14.5523 11 14ZM12 10C11.4477 10 11 10.4477 11 11C11 11.5523 11.4477 12 12 12H12.1C12.6523 12 13.1 11.5523 13.1 11C13.1 10.4477 12.6523 10 12.1 10H12Z"
    />
  </svg>

)
