import React, { SVGAttributes } from 'react'

export const Bot: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 0C0.895431 0 0 0.895431 0 2V10C0 11.1046 0.895431 12 2 12H14C15.1046 12 16 11.1046 16 10V2C16 0.895431 15.1046 0 14 0H2ZM2 7C2 5.89543 2.89543 5 4 5C5.10457 5 6 5.89543 6 7C6 8.10457 5.10457 9 4 9C2.89543 9 2 8.10457 2 7ZM10 7C10 5.89543 10.8954 5 12 5C13.1046 5 14 5.89543 14 7C14 8.10457 13.1046 9 12 9C10.8954 9 10 8.10457 10 7Z"
    />
    <rect y="14" width="16" height="2" rx="1" />
  </svg>
)
