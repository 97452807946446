import React, { SVGAttributes } from 'react'

export const VisibilityOff: React.FC<SVGAttributes<SVGSVGElement>> = (
  props,
) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.72154 21.4644L17.621 2.58588L15.889 1.58588L13.558 5.62322C13.0517 5.5433 12.5313 5.49999 12 5.49999C9.38223 5.49999 7.02801 6.55138 5.33162 7.85334C4.48232 8.50518 3.78544 9.22912 3.29649 9.93367C2.81686 10.6248 2.5 11.3515 2.5 12C2.5 12.6485 2.81686 13.3752 3.29649 14.0663C3.78544 14.7709 4.48232 15.4948 5.33162 16.1466C5.805 16.51 6.32961 16.8538 6.89688 17.1607L4.98949 20.4644L6.72154 21.4644ZM8.77293 13.9112C8.44073 13.3516 8.25 12.698 8.25 12C8.25 9.92892 9.92893 8.24999 12 8.24999C12.0138 8.24999 12.0276 8.25006 12.0413 8.25021L11.0559 9.95706C10.2849 10.314 9.75 11.0945 9.75 12C9.75 12.0685 9.75306 12.1363 9.75906 12.2032L8.77293 13.9112ZM15.75 12C15.75 13.8881 14.3546 15.4503 12.5388 15.7116L10.9606 18.445C11.3017 18.481 11.6485 18.5 12 18.5C14.6178 18.5 16.972 17.4486 18.6684 16.1466C19.5177 15.4948 20.2146 14.7709 20.7035 14.0663C21.1831 13.3752 21.5 12.6485 21.5 12C21.5 11.3515 21.1831 10.6248 20.7035 9.93367C20.2146 9.22912 19.5177 8.50518 18.6684 7.85334C18.3142 7.58152 17.9314 7.32061 17.5234 7.07792L15.484 10.6102C15.6556 11.04 15.75 11.509 15.75 12Z"
    />
  </svg>
)
