import React, { SVGAttributes } from 'react'

export const Flag: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="#188967" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.97429 2.6038L4.94473 2.59391C4.82422 2.55354 4.64581 2.49376 4.48246 2.46469C4.282 2.42901 3.89114 2.39773 3.51906 2.66591C3.14697 2.9341 3.05305 3.31479 3.02351 3.51624C2.99943 3.68041 2.99972 3.86856 2.99991 3.99565L2.99995 4.02682V12.6664C2.99995 13.2187 3.44766 13.6664 3.99995 13.6664C4.55223 13.6664 4.99995 13.2187 4.99995 12.6664V9.3872L10.8932 7.4228L10.9402 7.40713C11.1919 7.32335 11.469 7.23103 11.6769 7.12989C11.8153 7.06257 12.4594 6.74924 12.4594 5.99978C12.4594 5.25031 11.8146 4.93665 11.6762 4.86933C11.4683 4.76819 11.1919 4.6762 10.9402 4.59243L10.8932 4.57675L4.97429 2.6038Z"
    />
  </svg>
)
