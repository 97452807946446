import React from 'react'
import cx from 'classnames'

import styles from './Txt.module.scss'

type Div = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export interface TxtProps extends Div {
  children: React.ReactNode,
  variant?: 'primary' | 'secondary'
}

export const Txt: React.FC<TxtProps> = ({
  children,
  className,
  variant = 'primary',
  ...props
}) => {
  return (
    <div
      {...props}
      className={cx(styles.root, styles[variant], className, 'txt')}
    >
      {children}
    </div>
  )
}
