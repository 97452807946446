import React, { SVGAttributes } from 'react'

export const UserPlus: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 13C14.7614 13 17 10.7614 17 8C17 5.23858 14.7614 3 12 3C9.23858 3 7 5.23858 7 8C7 10.7614 9.23858 13 12 13ZM12 15C12.4456 15 12.8883 15.0257 13.3249 15.0763C11.9932 15.3825 11 16.5753 11 18C11 19.6569 12.3431 21 14 21H15V21.074C11.4437 21.3594 7.85678 21.1365 4.34843 20.4054C3.79562 20.2902 3.46638 19.7117 3.74108 19.2183C4.34666 18.1307 5.30069 17.1749 6.52112 16.4465C8.09292 15.5085 10.0188 15 12 15ZM18 12.75C18.6904 12.75 19.25 13.3096 19.25 14V16.75H22C22.6904 16.75 23.25 17.3096 23.25 18C23.25 18.6904 22.6904 19.25 22 19.25H19.25V22C19.25 22.6904 18.6904 23.25 18 23.25C17.3096 23.25 16.75 22.6904 16.75 22V19.25H14C13.3096 19.25 12.75 18.6904 12.75 18C12.75 17.3096 13.3096 16.75 14 16.75L16.75 16.75V14C16.75 13.3096 17.3096 12.75 18 12.75Z"
    />
  </svg>
)
