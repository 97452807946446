import React, { SVGAttributes } from 'react'

export const LogoName: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={210}
    height={40}
    color="current"
    fill="white"
    style={{
      shapeRendering: 'geometricPrecision',
      textRendering: 'geometricPrecision',
      fillRule: 'evenodd',
      clipRule: 'evenodd',
    }}
    viewBox="0 0 1250 281.31"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <style type="text/css" />
      <mask id="id0">
        <linearGradient
          id="id1"
          gradientUnits="userSpaceOnUse"
          x1={143.96}
          y1={119.75}
          x2={12.51}
          y2={119.75}
        >
          <stop
            offset={0}
            style={{
              stopOpacity: 1,
              stopColor: 'white',
            }}
          />
          <stop
            offset={1}
            style={{
              stopOpacity: 0,
              stopColor: 'white',
            }}
          />
        </linearGradient>
        <rect
          style={{
            fill: 'url(#id1)',
          }}
          x={76.01}
          y={61.91}
          width={80.07}
          height={65.42}
        />
      </mask>
      <mask id="id2">
        <linearGradient
          id="id3"
          gradientUnits="userSpaceOnUse"
          x1={143.96}
          y1={119.75}
          x2={12.51}
          y2={119.75}
        >
          <stop
            offset={0}
            style={{
              stopOpacity: 1,
              stopColor: 'white',
            }}
          />
          <stop
            offset={1}
            style={{
              stopOpacity: 0,
              stopColor: 'white',
            }}
          />
        </linearGradient>
        <rect
          style={{
            fill: 'url(#id3)',
          }}
          x={47.53}
          y={62.16}
          width={16.52}
          height={64.93}
        />
      </mask>
      <mask id="id4">
        <linearGradient
          id="id5"
          gradientUnits="userSpaceOnUse"
          x1={143.96}
          y1={119.75}
          x2={12.51}
          y2={119.75}
        >
          <stop
            offset={0}
            style={{
              stopOpacity: 1,
              stopColor: 'white',
            }}
          />
          <stop
            offset={1}
            style={{
              stopOpacity: 0,
              stopColor: 'white',
            }}
          />
        </linearGradient>
        <rect
          style={{
            fill: 'url(#id5)',
          }}
          x={21.6}
          y={62.28}
          width={13.6}
          height={64.69}
        />
      </mask>
      <mask id="id6">
        <linearGradient
          id="id7"
          gradientUnits="userSpaceOnUse"
          x1={143.96}
          y1={119.75}
          x2={12.51}
          y2={119.75}
        >
          <stop
            offset={0}
            style={{
              stopOpacity: 1,
              stopColor: 'white',
            }}
          />
          <stop
            offset={1}
            style={{
              stopOpacity: 0,
              stopColor: 'white',
            }}
          />
        </linearGradient>
        <rect
          style={{
            fill: 'url(#id7)',
          }}
          x={67.66}
          y={150.25}
          width={87.09}
          height={64.69}
        />
      </mask>
      <mask id="id8">
        <linearGradient
          id="id9"
          gradientUnits="userSpaceOnUse"
          x1={143.96}
          y1={119.75}
          x2={12.51}
          y2={119.75}
        >
          <stop
            offset={0}
            style={{
              stopOpacity: 1,
              stopColor: 'white',
            }}
          />
          <stop
            offset={1}
            style={{
              stopOpacity: 0,
              stopColor: 'white',
            }}
          />
        </linearGradient>
        <rect
          style={{
            fill: 'url(#id9)',
          }}
          x={39.44}
          y={150.13}
          width={16.52}
          height={64.93}
        />
      </mask>
      <mask id="id10">
        <linearGradient
          id="id11"
          gradientUnits="userSpaceOnUse"
          x1={143.96}
          y1={119.75}
          x2={12.51}
          y2={119.75}
        >
          <stop
            offset={0}
            style={{
              stopOpacity: 1,
              stopColor: 'white',
            }}
          />
          <stop
            offset={1}
            style={{
              stopOpacity: 0,
              stopColor: 'white',
            }}
          />
        </linearGradient>
        <rect
          style={{
            fill: 'url(#id11)',
          }}
          x={21.6}
          y={150.26}
          width={6.14}
          height={64.67}
        />
      </mask>
    </defs>
    <g id="\u0421\u043B\u043E\u0439_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer" />
      <g id="_2449912200048">
        <path
          className="fil0"
          style={{
            mask: 'url(#id0)',
          }}
          d="M92.69 102.67c0,2.91 0.95,5.27 2.82,7.01 1.82,1.74 4.17,2.63 7.16,2.67l36.75 0 0 -35.86 -36.75 0c-0.19,0 -0.39,0 -0.56,0.02 -2.69,0.09 -4.92,1.02 -6.64,2.69 -1.84,1.69 -2.78,4.06 -2.78,7.06l0 16.41zm-15.15 -26.16c0.98,-2.86 2.58,-5.45 4.82,-7.82 3.9,-4.26 9.29,-6.4 16.13,-6.4l57.22 0 0 54.99c0,5.34 -4.39,9.68 -9.83,9.68l-47.39 0c-6.73,0 -12.12,-2.15 -16.13,-6.52 -3.98,-4.3 -5.97,-9.27 -5.97,-14.85l0 -21.93c0,-2.54 0.37,-4.92 1.15,-7.14z"
        />
        <polygon
          className="fil0"
          style={{
            mask: 'url(#id2)',
          }}
          points="63.92,126.96 47.66,126.96 47.66,62.29 63.92,62.29 "
        />
        <path
          className="fil0"
          style={{
            mask: 'url(#id4)',
          }}
          d="M21.61 62.29l13.58 0 0 54.99c0,5.34 -4.39,9.68 -9.83,9.68l-3.75 0 0 -64.67z"
        />
        <path
          className="fil0"
          style={{
            mask: 'url(#id6)',
          }}
          d="M109.15 203.03l4.5 0c3,0 5.84,-1.35 7.71,-3.69l17.56 -21.97 0 37.56 15.81 0 0 -56.94c0,-4.27 -3.52,-7.73 -7.84,-7.73l-5.04 0c-2.43,0 -4.71,1.1 -6.19,2.97l-24.66 31.09 -24.72 -31.09c-1.48,-1.87 -3.76,-2.97 -6.17,-2.97l-4.58 0c-4.34,0 -7.86,3.47 -7.86,7.73l0 56.94 16.26 0 0 -37.56 17.5 21.95c1.85,2.34 4.71,3.71 7.72,3.71z"
        />
        <polygon
          className="fil0"
          style={{
            mask: 'url(#id8)',
          }}
          points="55.83,214.93 39.57,214.93 39.57,150.26 55.83,150.26 "
        />
        <polygon
          className="fil0"
          style={{
            mask: 'url(#id10)',
          }}
          points="21.61,150.27 27.73,150.27 27.73,214.92 21.61,214.92 "
        />
        <path
          className="fil0"
          d="M171.9 57.68l-150.29 -32.62 0 243.44 150.29 -51.64 0 -159.19zm-168.81 -56.99l173.9 38.78 0 181.03c-59,20.27 -118,40.54 -176.99,60.81l0 -281.31 3.09 0.69z"
        />
        <path
          className="fil0"
          d="M256.72 102.67c0,2.91 -0.95,5.27 -2.82,7.01 -1.82,1.74 -4.17,2.63 -7.16,2.67l-36.75 0 0 -35.86 36.75 0c0.19,0 0.39,0 0.56,0.02 2.69,0.09 4.92,1.02 6.64,2.69 1.84,1.69 2.78,4.06 2.78,7.06l0 16.41zm15.15 -26.16c-0.98,-2.86 -2.58,-5.45 -4.82,-7.82 -3.9,-4.26 -9.29,-6.4 -16.13,-6.4l-57.22 0 0 54.99c0,5.34 4.39,9.68 9.83,9.68l47.39 0c6.73,0 12.12,-2.15 16.13,-6.52 3.98,-4.3 5.97,-9.27 5.97,-14.85l0 -21.93c0,-2.54 -0.37,-4.92 -1.15,-7.14z"
        />
        <polygon
          className="fil0"
          points="285.49,126.96 301.75,126.96 301.75,62.29 285.49,62.29 "
        />
        <path
          className="fil0"
          d="M378.85 112.35l-48.33 0 0 -50.06 -16.3 0 0 54.99c0,5.34 4.39,9.68 9.83,9.68l54.81 0 0 -14.61z"
        />
        <path
          className="fil0"
          d="M391.32 126.96l52.95 -61.27c1.87,-2.15 4.6,-3.39 7.47,-3.39l19.78 0c5.43,0 9.83,4.34 9.83,9.68l0 54.99 -16.26 0 0 -50.48 -8.77 0 -20.02 22.71 18.19 0 0 12.46 -29.17 0 -13.48 15.31 -20.52 0z"
        />
        <path
          className="fil0"
          d="M561.32 126.95l-5.32 0c-2.09,0 -4.1,-0.82 -5.58,-2.28l-40.21 -39.88 0 42.16 -16.39 0 0 -56.92c0,-4.28 3.51,-7.73 7.85,-7.73l5.26 0c2.1,0 4.11,0.82 5.58,2.28l40.2 40.03 0 -42.31 16.45 0 0 56.92c0,4.27 -3.52,7.73 -7.84,7.73z"
        />
        <path
          className="fil0"
          d="M240.26 203.03l-4.5 0c-3,0 -5.84,-1.35 -7.71,-3.69l-17.56 -21.97 0 37.56 -15.81 0 0 -56.94c0,-4.27 3.52,-7.73 7.84,-7.73l5.04 0c2.43,0 4.71,1.1 6.19,2.97l24.66 31.09 24.72 -31.09c1.48,-1.87 3.76,-2.97 6.17,-2.97l4.58 0c4.34,0 7.86,3.47 7.86,7.73l0 56.94 -16.26 0 0 -37.56 -17.5 21.95c-1.85,2.34 -4.71,3.71 -7.72,3.71z"
        />
        <polygon
          className="fil0"
          points="293.58,214.93 309.84,214.93 309.84,150.26 293.58,150.26 "
        />
        <path
          className="fil0"
          d="M385.41 188.07c4,-4.45 6.04,-9.74 6.04,-15.8 0,-5.97 -2.04,-11.16 -6.04,-15.5 -4.02,-4.36 -9.25,-6.51 -15.72,-6.51l-48.01 0 0 64.65 16.29 0 0.04 -50.26 29.82 0c2.28,0 4.06,0.76 5.43,2.32 1.41,1.54 2.13,3.38 2.13,5.49 0,2.09 -0.72,3.97 -2.13,5.58 -1.47,1.58 -3.3,2.33 -5.58,2.33l-13.78 0c-4.34,0 -6.01,3.34 -6.01,5.28l0.02 3.34c0,0.54 0.06,1.09 0.18,1.61 0.33,1.5 1.22,2.84 2.34,3.89l19.56 20.41 22.45 0 -21.56 -20.23c5.82,-0.28 10.66,-2.45 14.52,-6.62z"
        />
        <path
          className="fil0"
          d="M542.89 200.31c3.02,-0.04 5.43,-0.97 7.17,-2.65 1.85,-1.7 2.8,-4.06 2.8,-7.03l0 -16.5c-0.06,-3.02 -1,-5.38 -2.8,-7.07 -1.84,-1.8 -4.19,-2.67 -7.17,-2.67l-27.96 0c-2.93,0 -5.3,0.87 -7.14,2.67 -1.74,1.74 -2.69,4.1 -2.78,7.07l0 16.65c0,2.87 0.95,5.17 2.78,6.88 1.93,1.8 4.3,2.65 7.14,2.65l27.96 0zm-54.27 -28.53c0,-5.84 1.98,-10.87 5.95,-15.11 4,-4.24 9.4,-6.4 16.15,-6.4l36.32 0c6.86,0 12.23,2.15 16.17,6.4 3.97,4.21 5.95,9.18 5.95,14.96l0 21.93c0,5.58 -1.98,10.55 -6.01,14.85 -4.01,4.36 -9.35,6.51 -16.11,6.51l-36.32 0c-6.66,0 -12,-2.15 -16.11,-6.51 -4.01,-4.36 -5.99,-9.32 -5.99,-15.02l0 -21.62z"
        />
        <path
          className="fil0"
          d="M650.51 188.07c4,-4.45 6.04,-9.74 6.04,-15.8 0,-5.97 -2.04,-11.16 -6.04,-15.5 -4.02,-4.36 -9.26,-6.51 -15.72,-6.51l-53.79 0 0 64.65 16.29 0 0.04 -50.26 35.6 0c2.28,0 4.06,0.76 5.43,2.32 1.41,1.54 2.13,3.38 2.13,5.49 0,2.09 -0.72,3.97 -2.13,5.58 -1.47,1.58 -3.3,2.33 -5.58,2.33l-13.78 0c-4.34,0 -6.01,3.34 -6.01,5.28l0.02 3.34c0,0.54 0.06,1.09 0.18,1.61 0.33,1.5 1.22,2.84 2.34,3.89l19.56 20.41 22.45 0 -21.56 -20.23c5.82,-0.28 10.66,-2.45 14.52,-6.62z"
        />
        <path
          className="fil0"
          d="M469.46 188.07c4.16,-4.45 6.28,-9.74 6.28,-15.8 0,-5.97 -2.12,-11.16 -6.28,-15.5 -4.18,-4.36 -9.62,-6.51 -16.35,-6.51l-48.82 0 0 64.65 16.94 0 0.04 -50.26 29.91 0c2.37,0 4.22,0.76 5.65,2.32 1.47,1.54 2.22,3.38 2.22,5.49 0,2.09 -0.75,3.97 -2.22,5.58 -1.52,1.58 -3.43,2.33 -5.8,2.33l-14.33 0c-4.51,0 -6.25,3.34 -6.25,5.28l0.02 3.34c0,0.54 0.06,1.09 0.19,1.61 0.35,1.5 1.27,2.84 2.43,3.89l20.34 20.41 23.35 0 -22.42 -20.23c6.05,-0.28 11.08,-2.45 15.1,-6.62z"
        />
      </g>
    </g>
  </svg>
)
