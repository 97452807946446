import React, { SVGAttributes } from 'react'

export const Chat: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5433 6.7039C21 7.80653 21 9.20435 21 12C21 14.7956 21 16.1935 20.5433 17.2961C19.9343 18.7663 18.7663 19.9343 17.2961 20.5433C16.1935 21 14.7956 21 12 21H9C6.17157 21 4.75736 21 3.87868 20.1213C3 19.2426 3 17.8284 3 15V12C3 9.20435 3 7.80653 3.45672 6.7039C4.06569 5.23373 5.23373 4.06569 6.7039 3.45672C7.80653 3 9.20435 3 12 3C14.7956 3 16.1935 3 17.2961 3.45672C18.7663 4.06569 19.9343 5.23373 20.5433 6.7039ZM8 9.99951C8 9.44723 8.44772 8.99951 9 8.99951H15C15.5523 8.99951 16 9.44723 16 9.99951C16 10.5518 15.5523 10.9995 15 10.9995H9C8.44772 10.9995 8 10.5518 8 9.99951ZM8 13.9995C8 13.4472 8.44772 12.9995 9 12.9995H12C12.5523 12.9995 13 13.4472 13 13.9995C13 14.5518 12.5523 14.9995 12 14.9995H9C8.44772 14.9995 8 14.5518 8 13.9995Z"
    />
  </svg>
)
