import React, { SVGAttributes } from 'react'

export const Camera: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5 16.4235C5 14.7169 6.38352 13.3333 8.09017 13.3333V13.3333C9.26064 13.3333 10.3307 12.672 10.8541 11.6251L12.2278 8.87782C12.7657 7.80199 13.0346 7.26407 13.5179 6.96537C14.0012 6.66667 14.6027 6.66667 15.8055 6.66667H24.1945C25.3973 6.66667 25.9988 6.66667 26.4821 6.96537C26.9654 7.26407 27.2343 7.80198 27.7722 8.87782L29.1459 11.6251C29.6693 12.672 30.7394 13.3333 31.9098 13.3333V13.3333C33.6165 13.3333 35 14.7169 35 16.4235V27.3333C35 30.1618 35 31.576 34.1213 32.4547C33.2426 33.3333 31.8284 33.3333 29 33.3333H11C8.17157 33.3333 6.75736 33.3333 5.87868 32.4547C5 31.576 5 30.1618 5 27.3333V16.4235Z"
      stroke="white"
      strokeWidth="4"
    />
    <path
      d="M24.6663 21.6667C24.6663 24.244 22.577 26.3333 19.9997 26.3333C17.4223 26.3333 15.333 24.244 15.333 21.6667C15.333 19.0893 17.4223 17 19.9997 17C22.577 17 24.6663 19.0893 24.6663 21.6667Z"
      stroke="white"
      strokeWidth="4"
    />
  </svg>
)
