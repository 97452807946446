import { forwardRef, SVGProps } from 'react'

enum LoaderPoints {
  RADIUS = 5
}

export const LoaderFloatingPoints = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => (
  <svg
    {...props}
    ref={ref}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 48 48"
    xmlSpace="preserve"
    width="48"
    height="48"
  >
    <circle fill="var(--blue-default-color)" cx="8" cy="24" r={LoaderPoints.RADIUS}>
      <animateTransform
        attributeName="transform"
        dur="1s"
        type="translate"
        values="0 12; 0 -12; 0 12"
        repeatCount="indefinite"
        begin="0.1"
      />
    </circle>
    <circle fill="var(--blue-semi-light-color)" cx="24" cy="24" r={LoaderPoints.RADIUS}>
      <animateTransform
        attributeName="transform"
        dur="1s"
        type="translate"
        values="0 8; 0 -8; 0 8"
        repeatCount="indefinite"
        begin="0.2"
      />
    </circle>
    <circle fill="var(--blue-light-color)" cx="40" cy="24" r={LoaderPoints.RADIUS}>
      <animateTransform
        attributeName="transform"
        dur="1s"
        type="translate"
        values="0 4; 0 -4; 0 4"
        repeatCount="indefinite"
        begin="0.3"
      />
    </circle>
  </svg>
))
