/* eslint-disable class-methods-use-this,@typescript-eslint/no-unused-vars */
import React, { ComponentType, Suspense, useEffect, useState } from 'react'
import { Loader } from '../UIKit/Loader'
import { Txt } from '../UIKit/Txt'

interface Progress {
  progress?: number
}

class FakeProgress {
  fastLimitPercent = 0.78

  fastPercent = 0.045

  slowPercent = 0.001

  chunks: number[] = []

  progress = 1

  public interval = 100

  private recalculateChunk() {
    this.chunks = this.chunks
      .filter((chunk) => {
        const isDuplicate = this.chunks.filter((c) => c !== chunk).length >= 2
        return chunk >= this.progress || !isDuplicate
      })
      .sort((a, b) => a - b)
  }

  public addChunk(chunkSize: number) {
    this.chunks.push(chunkSize)
    this.recalculateChunk()
  }

  private getFastProgress() {
    return this.progress * (this.fastPercent + this.ratio)
  }

  private getSlowProgress() {
    return this.progress * this.slowPercent
  }

  get ratio() {
    return this.chunks.length / 1000
  }

  private isFast() {
    const lastChunk = this.chunks[this.chunks.length - 1] || 1
    return this.progress <= lastChunk * this.fastLimitPercent
  }

  public get() {
    if (this.isFast()) {
      this.progress += this.getFastProgress()
    } else {
      this.progress += this.getSlowProgress()
    }

    this.recalculateChunk()

    return this.progress
  }
}

const Progress = new FakeProgress()

export const SuspenseLoader: React.FC<Progress> = ({ progress }) => {
  const [value, setValue] = useState(Progress.get())

  useEffect(() => {
    if (!progress) {
      return
    }
    Progress.addChunk(progress)

    const intervalId = setInterval(() => {
      const newValue = Progress.get()
      setValue(newValue)
    }, Progress.interval)

    return () => {
      clearInterval(intervalId)
    }
  }, [progress])

  return (
    <div
      style={{
        height: '100%',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: 'var(--app-padding)',
      }}
    >
      <Loader size="big" center />
      {typeof progress === 'number' &&
        <Txt className="mt-16">
          {value <= 100
            ? `${value.toFixed(2)} %`
            : 'Coloring the buttons...'
          }
        </Txt>
      }
    </div>
  )
}

function withSuspense<T>(WrappedComponent: ComponentType<T>, props?: Progress) {
  return (hocProps: T) => (
    <Suspense fallback={<SuspenseLoader progress={props?.progress} />}>
      {/* @ts-ignore */}
      <WrappedComponent {...hocProps} />
    </Suspense>
  )
}

export default withSuspense
