import React, { SVGAttributes } from 'react'

export const Lightning: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00016 14.6666C11.6821 14.6666 14.6668 11.6819 14.6668 7.99998C14.6668 4.31808 11.6821 1.33331 8.00016 1.33331C4.31826 1.33331 1.3335 4.31808 1.3335 7.99998C1.3335 11.6819 4.31826 14.6666 8.00016 14.6666ZM8.00016 9.65913V11.7299C8.00016 12.6205 8.00016 13.0658 8.19639 13.1091C8.39261 13.1523 8.57971 12.7482 8.9539 11.9399L10.126 9.4082C10.6175 8.34653 10.8633 7.8157 10.6877 7.40608C10.6446 7.30552 10.5853 7.21272 10.5122 7.13136C10.2142 6.79998 9.62924 6.79998 8.45932 6.79998C8.26306 6.79998 8.16494 6.79998 8.09746 6.74792C8.0805 6.73484 8.0653 6.71964 8.05222 6.70268C8.00016 6.6352 8.00016 6.53708 8.00016 6.34083V4.2701C8.00016 3.37944 8.00016 2.93411 7.80394 2.89089C7.60771 2.84768 7.42062 3.2518 7.04643 4.06004L5.87434 6.59176C5.38283 7.65343 5.13707 8.18426 5.31261 8.59388C5.3557 8.69444 5.415 8.78724 5.48815 8.8686C5.78612 9.19998 6.37108 9.19998 7.54101 9.19998C7.73726 9.19998 7.83539 9.19998 7.90286 9.25203C7.91982 9.26512 7.93503 9.28032 7.94811 9.29728C8.00016 9.36476 8.00016 9.46288 8.00016 9.65913Z"
    />
  </svg>

)
