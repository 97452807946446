import React, { CSSProperties } from 'react'
import cx from 'classnames'

import styles from './Loader.module.scss'

export interface ILoaderProps {
  size?: 'big' | 'small' | 'medium',
  center?: boolean,
  className?: string
  style?: CSSProperties
}

export const Loader: React.FC<ILoaderProps> = ({
  size = 'medium',
  center = false,
  className,
  style,
}) => {
  return (
    <div
      className={cx(
        styles.spinner,
        styles[size],
        center && styles.center,
        className,
      )}
      style={style}
    />
  )
}
