import React, { SVGAttributes } from 'react'

export const Send: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0551 7.58283C20.7637 6.54434 19.9571 5.14722 18.7034 5.24163L4.27326 6.32832C3.17126 6.4113 2.5343 7.61702 3.08687 8.57408L5.72386 13.1415L9.02257 11.3855C9.28885 11.2438 9.61957 11.3385 9.7704 11.5998C9.90387 11.831 9.85553 12.1243 9.65492 12.3004L6.72386 14.8735L9.36085 19.4409C9.91341 20.398 11.2761 20.4492 11.8989 19.5364L20.0551 7.58283Z"
    />
  </svg>
)
