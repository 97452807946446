import React, { SVGAttributes } from 'react'

export const ArrowUp: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="14" height="9" viewBox="0 0 14 9" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7071 7.29292L12.2929 8.70714L7 3.41424L1.70711 8.70713L0.292892 7.29292L7 0.585815L13.7071 7.29292Z"
      fill="currentColor"
    />
  </svg>
)
