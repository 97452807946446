import React, { SVGAttributes } from 'react'

export const RadioNotChecked: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#filter0_i_3001_1121)">
      <rect width="16" height="16" rx="8" fill="currentColor" color={'#F8F9FD'} />
    </g>
    <defs>
      <filter
        id="filter0_i_3001_1121"
        x="0"
        y="0"
        width="16"
        height="17"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.54902 0 0 0 0 0.556863 0 0 0 0 0.647059 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_3001_1121" />
      </filter>
    </defs>
  </svg>
)
