import React, { SVGAttributes } from 'react'

export const Location: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    color="#347DFE"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M10 10.1397C10 11.2597 10.8954 12.1676 12 12.1676C13.1046 12.1676 14 11.2597 14 10.1397C14 9.01966 13.1046 8.11173 12 8.11173C10.8954 8.11173 10 9.01966 10 10.1397Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5408 23.6417C7.64684 22.1456 1.35743 18.2504 0.17507 12.1676C-0.81039 7.09776 2.26217 0 12 0C21.7378 0 24.8104 7.09776 23.8249 12.1676C22.6426 18.2504 16.3532 22.1456 13.4592 23.6417C12.5352 24.1194 11.4648 24.1194 10.5408 23.6417ZM8 10.1397C8 12.3796 9.79086 14.1955 12 14.1955C14.2091 14.1955 16 12.3796 16 10.1397C16 7.89967 14.2091 6.0838 12 6.0838C9.79086 6.0838 8 7.89967 8 10.1397Z"
    />
  </svg>
)
