import React, { SVGAttributes } from 'react'

export const Headphones: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" color="#347DFE" {...props}>
    <rect x="16.0913" y="12" width="3.90909" height="7" rx="1.95455" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinejoin="round" />
    <rect x="4.36401" y="12" width="3.90909" height="7" rx="1.95455" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinejoin="round" />
    <path d="M4.36401 13V16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M20.0002 13V16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M20.0004 13C20.0004 10.6131 19.1767 8.32387 17.7105 6.63604C16.2443 4.94821 14.2557 4 12.1822 4C10.1087 4 8.1201 4.94821 6.65391 6.63604C5.18771 8.32387 4.36401 10.6131 4.36401 13" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)
