import React from 'react'
import { Loader } from '../Loader'

import styles from './LoaderWrapper.module.scss'

export interface LoaderWrapperProps {
  loading?: boolean
}

export const LoaderWrapper: React.FC<LoaderWrapperProps> = ({
  children,
  loading,
}) => {
  return (
    <div className={styles.root}>
      {children}
      {loading &&
      <div className={styles.loader}>
        <Loader size="big" />
      </div>
      }
    </div>
  )
}
