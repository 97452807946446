import React, { SVGAttributes } from 'react'

export const ArrowDown: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="14" height="9" viewBox="0 0 14 9" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.292846 1.70708L1.70706 0.292865L6.99995 5.58576L12.2928 0.292864L13.7071 1.70708L6.99995 8.41418L0.292846 1.70708Z"
      fill="currentColor"
    />
  </svg>
)
