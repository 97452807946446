import React, { SVGAttributes } from 'react'

export const Hexagon: React.FC<SVGAttributes<SVGSVGElement>> = ({ opacity = '0.24', ...props }) => (
  <svg
    viewBox="0.5 0 110 121"
    width="110"
    height="120"
    fill="currentColor"
    stroke="currentColor"
    color="#ACCDFF"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={opacity}
      d="M 47.001 2.744 C 52.261 -0.248 58.739 -0.248 63.999 2.744 L 102.002 24.368 C 107.261 27.36 110.5 32.891 110.5 38.876 L 110.5 82.124 C 110.5 88.109 107.261 93.639 102.002 96.631 L 63.999 118.255 C 58.739 121.248 52.261 121.248 47.001 118.255 L 8.998 96.631 C 3.739 93.639 0.5 88.109 0.5 82.124 L 0.5 38.876 C 0.5 32.891 3.739 27.36 8.998 24.368 L 47.001 2.744 Z"
      data-bx-origin="0.5 0.5"
    />
  </svg>
)
