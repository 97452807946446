import React, { SVGAttributes } from 'react'

export const ArrowRight: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.7072 5.29291L8.29299 6.70712L13.5859 12L8.29299 17.2929L9.7072 18.7071L16.4143 12L9.7072 5.29291Z"
    />
  </svg>
)
