import { initializeApp } from 'firebase/app'

const firebaseConfig = {
  apiKey: 'AIzaSyCjntpEjyy_JtJFgWgVjH3E5S_Lz19gdhc',
  authDomain: 'tiaglin.firebaseapp.com',
  projectId: 'tiaglin',
  storageBucket: 'tiaglin.appspot.com',
  messagingSenderId: '812770022069',
  appId: '1:812770022069:web:dd9cca25739862da9624fc',
  measurementId: 'G-PRV70WT2QN',
}

export const firebaseApp = initializeApp(firebaseConfig)
