import React, { SVGAttributes } from 'react'

export const Padlock: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.6668 5.33333V4.66667C10.6668 3.19391 9.47292 2 8.00016 2V2C6.5274 2 5.3335 3.19391 5.3335 4.66667V5.33333"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      fill="transparent"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.16835 6.67579C2 7.15691 2 7.75538 2 8.95234C2 10.5483 2 11.3463 2.22447 11.9877C2.62651 13.1367 3.5299 14.0401 4.67888 14.4422C5.32037 14.6666 6.11834 14.6666 7.71429 14.6666H8.28571C9.88166 14.6666 10.6796 14.6666 11.3211 14.4422C12.4701 14.0401 13.3735 13.1367 13.7755 11.9877C14 11.3463 14 10.5483 14 8.95234C14 7.75538 14 7.15691 13.8317 6.67579C13.5301 5.81405 12.8526 5.13651 11.9908 4.83498C11.5097 4.66663 10.9112 4.66663 9.71429 4.66663H6.28571C5.08876 4.66663 4.49028 4.66663 4.00916 4.83498C3.14742 5.13651 2.46989 5.81405 2.16835 6.67579ZM10 9.33329C10 10.0736 9.5978 10.7199 9 11.0657V12.6666H7V11.0657C6.4022 10.7199 6 10.0736 6 9.33329C6 8.22872 6.89543 7.33329 8 7.33329C9.10457 7.33329 10 8.22872 10 9.33329Z"
    />
  </svg>
)
