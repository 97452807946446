import React, { SVGAttributes } from 'react'

export const Support: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 12C0 21.882 2.118 24 12 24C21.882 24 24 21.882 24 12C24 2.118 21.882 0 12 0C2.118 0 0 2.118 0 12ZM5.08176 7.05699C5.3426 5.61602 6.20673 4.59936 7.37215 3.95987C8.70296 3.22962 10.3938 3 11.9751 3C13.5542 3 15.2556 3.22481 16.5975 3.95048C17.7742 4.58677 18.6537 5.60439 18.9179 7.05694C20.6069 7.30621 21 8.4523 21 12C21 16.1175 20.4705 17 18 17C15.5295 17 15 16.1175 15 12C15 8.60561 15.3599 7.40974 16.8703 7.0944C16.668 6.46253 16.2379 6.02971 15.6462 5.70974C14.732 5.21534 13.4209 5 11.9751 5C10.5314 5 9.23477 5.21912 8.33426 5.71325C7.75256 6.03244 7.32862 6.46434 7.12861 7.09418C8.63998 7.40919 9 8.60483 9 12C9 15.4912 8.61934 16.6567 6.99762 16.9305C6.9992 16.9534 7 16.9766 7 17C7 17.3519 7.10526 17.6043 7.28077 17.8141C7.47108 18.0415 7.78265 18.262 8.24752 18.4513C9.19232 18.836 10.539 19 12 19C12.5523 19 13 19.4477 13 20C13 20.5523 12.5523 21 12 21C10.4486 21 8.79527 20.8338 7.49318 20.3036C6.8346 20.0354 6.21099 19.6521 5.747 19.0976C5.26819 18.5255 5 17.818 5 17C5 16.9766 5.0008 16.9534 5.00238 16.9305C3.38066 16.6567 3 15.4912 3 12C3 8.45256 3.39303 7.30637 5.08176 7.05699Z"
    />
  </svg>
)
