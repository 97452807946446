import React, { SVGAttributes } from 'react'

export const Done: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7411 4.32556C12.0428 4.59956 12.0873 5.09316 11.8404 5.42804L8.04726 10.5737C7.55736 11.2383 6.67681 11.3414 6.0727 10.805L4.26495 9.19987C3.96053 8.92956 3.91117 8.43654 4.15471 8.09866C4.39824 7.76078 4.84244 7.706 5.14686 7.9763L6.95462 9.58146L10.7478 4.43581C10.9946 4.10092 11.4394 4.05156 11.7411 4.32556Z"
    />
  </svg>
)
