import React, { SVGAttributes } from 'react'

export const DoneDouble: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.663 4.32556C13.9647 4.59956 14.0092 5.09316 13.7623 5.42804L9.96913 10.5737C9.47923 11.2383 8.59869 11.3414 7.99457 10.805L6.18682 9.19987C5.8824 8.92956 5.83305 8.43654 6.07658 8.09866C6.32012 7.76078 6.76432 7.706 7.06874 7.9763L8.87649 9.58146L12.6697 4.43581C12.9165 4.10092 13.3612 4.05156 13.663 4.32556Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.29777 8.17701L10.3512 5.17334C10.5849 4.83139 10.4972 4.36469 10.1553 4.13092C9.81335 3.89716 9.34664 3.98486 9.11288 4.3268L7.21739 7.09948L8.29777 8.17701ZM6.52749 10.6484L5.42694 9.5508C5.36205 9.55544 5.29515 9.53497 5.24224 9.48747L4.25099 8.59772C3.94275 8.32103 3.46856 8.34662 3.19187 8.65486C2.91519 8.96311 2.94077 9.4373 3.24902 9.71398L4.24026 10.6037C4.90302 11.1986 5.87913 11.1878 6.52749 10.6484Z"
    />
  </svg>
)
