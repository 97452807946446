import React, { SVGAttributes } from 'react'

export const Check: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="#64A2FF" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6332 5.22607C19.0607 5.5758 19.1237 6.20582 18.7739 6.63327L10.381 16.8913C9.70231 17.7208 8.49044 17.8679 7.63306 17.2248L4.39997 14.8C3.95814 14.4687 3.8686 13.8419 4.19997 13.4C4.53134 12.9582 5.15814 12.8687 5.59997 13.2L8.83306 15.6248L17.226 5.36679C17.5757 4.93935 18.2058 4.87635 18.6332 5.22607Z"
    />
  </svg>

)
