import React, { SVGAttributes } from 'react'

export const Timer: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="#188967" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM19 12C19 15.866 15.866 19 12 19C8.13401 19 5 15.866 5 12C5 8.13401 8.13401 5 12 5C15.866 5 19 8.13401 19 12ZM12.0823 7.06584C12 7.14028 12 7.26019 12 7.5V11.7113C12 11.8522 12 11.9226 11.9665 11.9807C11.933 12.0387 11.872 12.0739 11.75 12.1443L8.10289 14.25C7.8952 14.3699 7.79136 14.4299 7.76803 14.5383C7.74471 14.6468 7.81017 14.7378 7.94109 14.9198C8.35381 15.4935 8.88466 15.9749 9.5 16.3301C10.2601 16.769 11.1223 17 12 17C12.8777 17 13.7399 16.769 14.5 16.3301C15.2601 15.8913 15.8913 15.2601 16.3301 14.5C16.769 13.7399 17 12.8777 17 12C17 11.1223 16.769 10.2601 16.3301 9.5C15.8913 8.7399 15.2601 8.10871 14.5 7.66987C13.8847 7.31461 13.2024 7.09554 12.4992 7.02498C12.2761 7.0026 12.1646 6.9914 12.0823 7.06584Z"
    />
  </svg>
)
