import React, { SVGAttributes } from 'react'

export const Diamond: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.1074 3H8.51703C7.06482 3 6.33871 3 5.80404 3.4033C5.26937 3.80661 5.06989 4.50478 4.67094 5.90112L4.07126 8H8.1699L9.1074 3ZM3.61719 9.92418C3.61949 9.94086 3.62234 9.95744 3.62576 9.97399C3.67548 10.2147 3.83779 10.4131 4.16241 10.8098L4.16241 10.8098L9.90831 17.8326L8.19493 10H3.99983C3.86429 10 3.73506 9.97304 3.61719 9.92418ZM15.0913 17.8326L20.8372 10.8098C21.1619 10.4131 21.3242 10.2147 21.3739 9.97399C21.3773 9.95744 21.3802 9.94086 21.3825 9.92418C21.2646 9.97304 21.1354 10 20.9998 10H16.8047L15.0913 17.8326ZM20.9284 8L20.3287 5.90112C19.9298 4.50478 19.7303 3.80661 19.1956 3.4033C18.6609 3 17.9348 3 16.4826 3H15.8923L16.8298 8H20.9284ZM13.8574 3H11.1423L10.2048 8H14.7949L13.8574 3ZM12.4998 20.3205L10.2422 10H14.7574L12.4998 20.3205Z"
    />
  </svg>
)
