import ReactDOM from 'react-dom'
import './firebase'

import './index.scss'

import './assets/images'

import { Buffer } from 'buffer'
import AppInit from './app/view/AppInit'
import { environment } from './environment'

window.Buffer = Buffer

const app = <AppInit />

ReactDOM.render(app, document.querySelector('#root'))

window.addEventListener('load', () => {
  const { hostname } = window.location

  if (
    'serviceWorker' in navigator
    && environment.PRODUCTION
    && hostname !== 'localhost'
  ) {
    navigator.serviceWorker.register('/sw.js')
  }
})
