import React, { SVGAttributes } from 'react'

export const Company: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    color="#ACCDFF"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.1019 1.50259C17.086 2.37736 17.7651 3.60723 17.9496 5.14137C17.9659 5.27685 18.0678 5.38783 18.2019 5.41284C20.972 5.92931 22.5023 7.00249 23.2851 9.02207C23.3325 9.14434 23.2895 9.28282 23.1839 9.36056C19.3879 12.1546 15.665 13.5 12 13.5C8.33502 13.5 4.61208 12.1546 0.816084 9.36056C0.71047 9.28282 0.667502 9.14434 0.714898 9.02207C1.49773 7.00249 3.02805 5.92931 5.79808 5.41284C5.93222 5.38783 6.03412 5.27685 6.05041 5.14137C6.23485 3.60723 6.91402 2.37736 7.89814 1.50259C9.04117 0.486564 10.5369 0 12 0C13.4631 0 14.9588 0.486564 16.1019 1.50259ZM12 5C10.6873 5 9.51152 5.02959 8.45956 5.09925C8.26386 5.11221 8.10622 4.93832 8.15768 4.74907C8.36215 3.99708 8.74973 3.42152 9.22686 2.99741C9.95883 2.34677 10.9631 2 12 2C13.0369 2 14.0412 2.34677 14.7731 2.99741C15.2503 3.42152 15.6379 3.99708 15.8423 4.74907C15.8938 4.93832 15.7361 5.11221 15.5404 5.09925C14.4885 5.02959 13.3127 5 12 5Z"
    />
    <path d="M0.575026 11.6395C0.387983 11.5125 0.13158 11.6261 0.10978 11.8511C0.0329427 12.6443 0 13.5243 0 14.5C0 22.3232 2.118 24 12 24C21.882 24 24 22.3232 24 14.5C24 13.5243 23.9671 12.6443 23.8902 11.8511C23.8684 11.6261 23.612 11.5125 23.425 11.6395C19.6739 14.1861 15.8616 15.5 12 15.5C8.13841 15.5 4.32613 14.1861 0.575026 11.6395Z" />
  </svg>
)
